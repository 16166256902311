/* new */
* {
  font-size: 10pt;
  line-height: 12pt;
  color: white;
}
p {
  margin: 0px 12px 12px 12px;
  text-align: justify;
}
p:first-of-type {
  font-size: 16pt;
  font-weight: bold;
  line-height: 40px;
}
a {
  font-weight: bold;
}
.Baixe-o-app {
  text-align: center;
}
.Anexos {
  text-align: center;
  margin-bottom: 36px;
}
.Footer {
  margin: 40px 0px 20px 0px;
  text-align: center;
}

.Header {
  margin: 20px 0px 0px 0px;
  text-align: center;
}
.App-logo {
  animation: App-logo-spin infinite 1s linear;
  height: 40vmin;
  pointer-events: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
